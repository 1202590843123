import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4c9a8022"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-radio-box", { disabled: _ctx.disabled }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.model = _ctx.value))
  }, [
    (!_ctx.isChecked)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['far', 'circle']
        }))
      : _createCommentVNode("", true),
    (_ctx.isChecked)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: ['fas', 'circle']
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ], 2))
}