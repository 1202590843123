
import { defineComponent } from 'vue';
import Dropzone from 'dropzone';

export default defineComponent({
  name: 'UiDropzone',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dropzone: {} as Dropzone,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
  },
  mounted() {
    const options = {
      ...{
        autoQueue: false,
        acceptedFiles: '.jpeg,.jpg,.png,.pdf',
        maxFiles: 1,
        maxFilesize: 1,
        previewsContainer: false,
        dictDefaultMessage: document.querySelector('.dz-message')?.innerHTML,
      },
      ...this.options,
    };

    const el = this.$refs['ui-dropzone'] as any;

    this.dropzone = new Dropzone(el, options);

    this.dropzone.on('thumbnail', (file, dataUrl) => {
      this.$emit('dropzone-thumbnail', file, dataUrl);
    });

    this.dropzone.on('addedfiles', (files) => {
      this.$emit('dropzone-addedfiles', files);
    });

    this.dropzone.on('sending', (file, xhr, formData) => {
      this.$emit('dropzone-sending', file, xhr, formData);
    });

    this.dropzone.on('success', (file, response) => {
      this.$emit('dropzone-success', file, response);
    });

    this.dropzone.on('error', (file, message) => {
      this.$emit('dropzone-error', file, message);
    });
  },
  methods: {
    processFiles() {
      const acceptedFiles = this.dropzone.getAcceptedFiles();
      acceptedFiles.forEach((file: any) => this.dropzone.processFile(file));
    },
    removeFile(file: Dropzone.DropzoneFile) {
      this.dropzone.removeFile(file);
    },
    removeAllFiles() {
      this.dropzone.removeAllFiles();
    },
  },
});
