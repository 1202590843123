
import { defineComponent } from 'vue';
import store from '@/store';
import UiErrors from '@/components/form/UiErrors.vue';
import VideoPlayer from '@/components/plyr/VideoPlayer.vue';
import UiOverlay from '../form/UiOverlay.vue';
import UiScroll from '../form/UiScroll.vue';
import UiDropzone from '../form/UiDropzone.vue';
import UiButton from '../form/UiButton.vue';
import UiRadioBox from '../form/UiRadioBox.vue';
import UiCheckBox from '../form/UiCheckBox.vue';
import UiTextarea from '../form/UiTextarea.vue';
import UiTextField from '../form/UiTextField.vue';

export default defineComponent({
  name: 'MediaManager',
  props: {
    modelValue: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    isProcessing: false,
    validationErrors: {} as any,
    errors: [] as Array<string>,
    mediaType: 'image',
    file: {
      path: '',
      description: '',
      link: {
        uri: '',
        text: '',
      },
      default: false,
      src: '',
      index: -1,
    } as any,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    imageUploadSize() {
      return Number(process.env.VUE_APP_IMAGE_UPLOAD_SIZE);
    },
    dropzoneOptions() {
      return {
        url: `${this.baseApiUrl}/api/media/image/upload`,
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
        acceptedFiles: '.jpeg,.jpg,.png',
        maxFilesize: this.imageUploadSize,
        maxFiles: 1,
      };
    },
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    isFileAdded() {
      if (
        this.mediaType === 'image'
        && ((this.file.path && this.file.path !== 'link') || this.file.src)
      ) {
        return true;
      }
      if (this.mediaType === 'video' && this.file.link.uri) {
        return true;
      }
      return false;
    },
    isImageSelected(): any {
      return (
        (this.isProcessing || this.mediaType === 'image')
        && (this.file?.src?.length || (this.file?.path?.length && this.file?.path !== 'link'))
      );
    },
    isVideoSelected(): any {
      return (
        (this.isProcessing || this.mediaType === 'video')
        && this.file?.link?.uri?.length
      );
    },
  },
  components: {
    UiErrors,
    UiOverlay,
    UiScroll,
    UiDropzone,
    UiButton,
    UiRadioBox,
    UiCheckBox,
    UiTextarea,
    UiTextField,
    VideoPlayer,
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.validationErrors = {};
      this.errors = [];
      this.mediaType = 'image';
    },
    removeFileExtension(input: string) {
      return input.substr(0, input.lastIndexOf('.')) || input;
    },
    dropzoneThumbnail(file: any, dataUrl: string) {
      if (!file.accepted) return;
      this.file.src = file.dataURL;
    },
    isValidUrl(value: string) {
      return /^(https:\/\/www\.|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(value);
    },
    isSupportedUrl(value: string) {
      if (/youtu.be\b/g.test(value)) return true;
      if (/youtube.com\b/g.test(value)) return true;
      if (/vimeo.com\b/g.test(value)) return true;
      return false;
    },
    processFiles() {
      this.validationErrors = {};

      if (this.mediaType === 'image') {
        if (this.file?.description?.length > 1000) {
          this.validationErrors.description = [
            'You can\'t use more than 1000 characters',
          ];
        }

        if (Object.keys(this.validationErrors).length) {
          this.$toast.show('Please enter correct data', 3000);
          return;
        }

        if (this.file.default) {
          this.model.forEach((el: any, i: number) => {
            this.model[i].default = false;
          });
        }

        if (this.file.index > -1) {
          this.model[this.file.index] = {
            path: this.file.path,
            description: this.file.description,
            link: {
              uri: '',
              text: '',
            },
            default: this.file.default,
          };

          if (!this.file.src) {
            this.closeModal();
            return;
          }
        }
      }

      if (this.mediaType === 'video') {
        if (!this.isValidUrl(this.file.link.uri)) {
          this.validationErrors.uri = [
            'URL is not valid',
          ];
        }

        if (!this.isSupportedUrl(this.file.link.uri)) {
          this.validationErrors.uri = [
            ...(this.validationErrors.uri || []),
            ...['Only Youtube or Vimeo videos are supported'],
          ];
        }

        if (Object.keys(this.validationErrors).length) {
          this.$toast.show('Please enter correct data', 3000);
          return;
        }

        if (this.file.index > -1) {
          this.model[this.file.index] = {
            path: 'link',
            description: this.file.description,
            link: {
              uri: this.file.link.uri,
              text: '',
            },
            default: false,
          };

          this.closeModal();
          return;
        }

        this.model.push({
          path: 'link',
          description: this.file.description,
          link: {
            uri: this.file.link.uri,
            text: '',
          },
          default: false,
        });

        this.closeModal();
        return;
      }

      this.isProcessing = true;
      (this.$refs['ui-dropzone'] as any).processFiles();
    },
    removeAllFiles() {
      (this.$refs['ui-dropzone'] as any).removeAllFiles();

      this.file.path = '';
      this.file.src = '';
    },
    dropzoneSuccess(file: any, response: any) {
      this.isProcessing = false;

      this.removeAllFiles();

      if (this.file.default) {
        this.model.forEach((el: any, i: number) => {
          this.model[i].default = false;
        });
      }

      if (this.file.index > -1) {
        this.model[this.file.index] = {
          path: response.id,
          description: this.file.description,
          link: {
            uri: '',
            text: '',
          },
          default: this.file.default,
        };
      } else {
        this.model.push({
          path: response.id,
          description: this.file.description,
          link: {
            uri: '',
            text: '',
          },
          default: this.file.default,
        });
      }

      this.closeModal();
    },
    dropzoneError(file: any, message: any) {
      this.removeAllFiles();

      this.isProcessing = false;

      if (file.size > this.imageUploadSize * 1024 * 1024) {
        this.errors.unshift(
          `${this.removeFileExtension(file.name)} (Image is too large)`,
        );
      }

      const typeRe = (/(?:\.([^.]+))?$/) as any;

      const type = typeRe.exec(file?.name)[1];

      if (type !== 'jpg' && type !== 'jpeg' && type !== 'png') {
        this.errors.unshift(
          `${this.removeFileExtension(file.name)} (Unsupported image format)`,
        );
      }

      if (message?.error?.message) {
        this.errors.unshift(
          `${this.removeFileExtension(file.name)} (${message.error.message})`,
        );
      }

      if (message === 'You can not upload any more files.') {
        this.errors.unshift('Only one image can be selected');
      }
    },
    addFile() {
      this.file = {
        path: '',
        description: '',
        link: {
          uri: '',
          text: '',
        },
        default: false,
        src: '',
        index: -1,
      };

      this.openModal();
    },
    editFile(model: any, i: number) {
      this.mediaType = model.path && model.path !== 'link' ? 'image' : 'video';

      this.file = {
        path: model.path,
        description: model.description || '',
        link: {
          uri: model.link ? model.link.uri : '',
          text: '',
        },
        default: model.default || false,
        src: '',
        index: i,
      };

      this.openModal();
    },
    removeFile(index: number) {
      this.model.splice(index, 1);
    },
  },
});
