
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiRadioBox',
  props: {
    modelValue: {
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    checked: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    isChecked(): boolean {
      return this.modelValue === this.value;
    },
  },
});
