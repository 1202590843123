import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-076dc66e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkmark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(`ui-checkbox ${_ctx.direction}`)
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      type: "checkbox",
      name: "vinordic"
    }, null, 512), [
      [_vModelCheckbox, _ctx.model]
    ]),
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, {
        class: "icon",
        icon: ['fal', 'times']
      })
    ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ], 2))
}